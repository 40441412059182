<template>
  <div class="bg-fixed bg-[url('/images/network-8303719_1920.jpg')] bg-no-repeat bg-cover">
    <div class="md:bg-gradient-to-r from-gray-900 from-10% via-transparent to-gray-900 to-90% hover:bg-transparent">
      <div class="container mx-auto">
        <div class="flex justify-center items-center h-[calc(100vh-86px)] md:h-96">
          <div class="text-center text-white">
            <h1 class="text-6xl md:text-6xl font-bold">
              Dunder Dev
            </h1>
            <p class="text-xl md:text-2xl mt-4">
              Learn AI, Blog, Courses, and more!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
